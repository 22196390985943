(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

+function ($, document) {
  $(document).foundation(); // document ready
  // --------------

  $(function () {
    // navicon toggle
    //---------------
    $('#navicon').on('click', function () {
      $(this).toggleClass('is-active');
      $('body').toggleClass('menu-active');
    });
    $('.language-select .language-toggle').click(function () {
      $(this).toggleClass('toggle-active');
      $(this).siblings().toggleClass('toggle-active');
    }); // show all accessory for mobile
    // -----------------------------

    $('.btn.show-more').click(function () {
      $('.card-product').show();
      $(this).hide();
    }); // popup gallery
    // -------------

    $('.popup-gallery').each(function () {
      $(this).magnificPopup({
        type: 'image',
        delegate: 'a',
        gallery: {
          enabled: true,
          tCounter: '%curr% of %total%'
        }
      });
    }); // YouTube video on click
    // ---------------------------

    $('.overlay').click(function () {
      $(".yt_thumbnail").hide();
      $(this).hide();
      var symbol = $(".yt_video")[0].src.indexOf("?") > -1 ? "&" : "?";
      $(".yt_video")[0].src += symbol + "autoplay=1";
    }); // product image slider
    // --------------------

    $('.product-slide').slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      speed: 100,
      fade: true,
      cssEase: 'linear',
      asNavFor: '.product-slide-nav'
    });
    $('.product-slide-nav').slick({
      slidesToShow: 3,
      slidesToScroll: 1,
      asNavFor: '.product-slide',
      centerMode: false,
      focusOnSelect: true,
      draggable: false,
      nextArrow: '<i class="button-next icon-arrow-right"></i>',
      prevArrow: '<i class="button-prev icon-arrow-left"></i>',
      responsive: [{
        // 1025 pixels down
        breakpoint: 1025,
        settings: {
          slidesToShow: 2
        }
      }, {
        // 768 pixels down
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          draggable: true
        }
      }]
    });
    $(function ($, win) {
      $.fn.inViewport = function (cb) {
        return this.each(function (i, el) {
          function visPx() {
            var H = $(this).height(),
                r = el.getBoundingClientRect(),
                t = r.top,
                b = r.bottom;
            return cb.call(el, Math.max(0, t > 0 ? H - t : b < H ? b : H));
          }

          visPx();
          $(win).on("resize scroll onload", visPx);
        });
      };
    }(jQuery, window));
    $('.counter-number').inViewport(function (px) {
      if (px > 0 && !this.initNumAnim) {
        this.initNumAnim = true; // Set flag to true to prevent re-running the same animation

        $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
        }, {
          duration: 3000,
          easing: 'swing',
          step: function step(now) {
            $(this).text(Math.ceil(now));
          }
        });
      }
    });
  });
}(jQuery, document);

},{}]},{},[1]);
